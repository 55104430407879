import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private readonly domSanitizer: DomSanitizer) {
  }

  transform(url: string | undefined): SafeResourceUrl | null {
    return url ? this.domSanitizer.bypassSecurityTrustResourceUrl(url) : null;
  }
}
