import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { SGWTConnectCore } from '@sgwt/connect-angular';
import { Subject, filter, iif, map, mergeMap, of, takeUntil, tap } from 'rxjs';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ISgwtConnectElement } from 'src/main';
import { ProviderModel } from '../../core/models/provider.model';
import { ProvidersService } from '../../core/services/providers.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();

  providerInfo?: ProviderModel | null = null;
  private callBackUrlRouteParamName = 'callback';

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly providerService: ProvidersService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const callBack = this.activatedRoute.snapshot.queryParamMap.get(this.callBackUrlRouteParamName);
    
    this.providerService.getProvider("greenscope").pipe(
      tap(result => {
        if (result){
          this.providerInfo = {
            name : result?.name!,
            webAppUri: result?.webAppUri!,
            callBackUri: callBack
          }
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngAfterViewInit(): void {
    this.analyticsService.trackEventNavigateHomePage();
  }
}
