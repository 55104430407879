import { formatDate } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { from, lastValueFrom, Observable } from 'rxjs';

declare const ENV_AVAILABLE_LANGUAGES: string;
declare const ENV_AVAILABLE_LANGUAGES_REGEX: string;

// https://developer.sgmarkets.com/docs/design-system/content/data-formats.html#date
const DEFAULT_FORMAT_KEY = 'DEFAULT';

const SG_DATE_FORMATS = new Map([
  ['fr', 'dd MMM yyyy'],
  ['en', 'd MMM yyyy'],
  [DEFAULT_FORMAT_KEY, 'mediumDate'],
]);
const SG_DATETIME_FORMATS = new Map([
  ['fr', "dd MMM yyyy HH'h'mm"], // eslint-disable-line @typescript-eslint/quotes
  ['en', 'd MMM yyyy hh:mm a'],
  [DEFAULT_FORMAT_KEY, 'medium'],
]);

const DEFAULT_LANG = 'en';

const SESSION_STORAGE_LANGUAGE = 'esg-platform:selectedLanguage';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  constructor(private translate: TranslateService) {
    const initLang = DEFAULT_LANG; //this.getNegociatedBrowserLang();
    translate.use(DEFAULT_LANG); // to avoid having no value set…
    this.use(initLang); // … until the promise here finishes.
    translate.setDefaultLang(initLang);
  }

  public getNegociatedBrowserLang(): string {
    const langRegex = new RegExp(ENV_AVAILABLE_LANGUAGES_REGEX);
    const browserLangs = window.navigator.languages || [];
    for (const lg of browserLangs) {
      const langMatch = langRegex.exec(lg);
      if (langMatch) {
        return langMatch[0];
      }
    }
    return DEFAULT_LANG;
  }

  public get allLangsForAccountCenter(): string {
    return ENV_AVAILABLE_LANGUAGES.toUpperCase();
  }

  public get allLangs(): string[] {
    return ENV_AVAILABLE_LANGUAGES.split(',');
  }

  public get currentLang(): string {
    return this.translate.currentLang;
  }

  public get onLangChange(): EventEmitter<LangChangeEvent> {
    return this.translate.onLangChange;
  }

  /**
   * No-op method kept for API compatibility with Translate.
   * The default language is actually negociated based on
   * the browser preferences and supported languages.
   */
  public setDefaultLang(_: string): void {
    /* nothing */
  }

  /**
   * The returned Observable contains the final language: the negociated
   * language if the change was successful, else the current language. No
   * subscription is needed for the language change to be triggered. No
   * unsubscription is needed: this Observable delivers at most one item.
   */
  public useNegociatedBrowserLang(): Observable<string> {
    return this.use(this.getNegociatedBrowserLang());
  }

  public getLangs(): Array<string> {
    return this.translate.getLangs();
  }

  /**
   * The returned Observable contains the final language: the requested
   * language if the change was successful, else the current language. No
   * subscription is needed for the language change to be triggered. No
   * unsubscription is needed: this Observable delivers at most one item.
   */
  public use(newLang: string): Observable<string> {
    return from(
      import(
        /* webpackChunkName: 'ng-i18n' */
        /* webpackMode: 'lazy-once' */
        /* webpackPreload: true */
        `node_modules/@angular/common/locales/global/${newLang}`
      )
        .then(() => lastValueFrom(this.translate.use(newLang)))
        .then(() => newLang)
        .catch(() => this.currentLang)
    );
  }

  public useLanguage(newLang: string) {
    this.translate.use(newLang);
  }

  public get(
    key: string | Array<string>,
    interpolateParams?: object
  ): Observable<string | any> {
    return this.translate.get(key, interpolateParams);
  }

  public getStreamOnTranslationChange(
    key: string | Array<string>,
    interpolateParams?: object
  ): Observable<string | any> {
    return this.translate.getStreamOnTranslationChange(key, interpolateParams);
  }

  public stream(
    key: string | Array<string>,
    interpolateParams?: object
  ): Observable<string | any> {
    return this.translate.stream(key, interpolateParams);
  }

  public instant(
    key: string | Array<string>,
    interpolateParams?: object
  ): string | any {
    return this.translate.instant(key, interpolateParams);
  }

  public formattedNgL10nDate(
    rawDate: string | Date,
    zone?: string,
    format?: string,
    lang?: string
  ): string {
    return this.formattedDate(rawDate, SG_DATE_FORMATS, zone, format, lang);
  }

  public formattedNgL10nDateTime(
    rawDate: string | Date,
    zone?: string,
    format?: string,
    lang?: string
  ): string {
    return this.formattedDate(rawDate, SG_DATETIME_FORMATS, zone, format, lang);
  }

  private formattedDate(
    rawDate: string | Date,
    known: Map<string, string>,
    zone?: string,
    format?: string,
    lang = this.currentLang
  ): string {
    let ngFormat = format;
    let ngZone = zone;
    if (!ngFormat) {
      ngFormat = known.get(lang);
    }
    if (!ngFormat) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ngFormat = known.get(DEFAULT_FORMAT_KEY)!;
    } // never undefined
    if (!ngZone) {
      ngZone = 'Europe/Paris';
    }
    if (ngZone.indexOf('/') >= 0) {
      ngZone =
        new Intl.DateTimeFormat('en', { timeZone: zone, timeZoneName: 'short' })
          .format(new Date(rawDate))
          .split(' ')[1] || zone;
    }
    return formatDate(rawDate, ngFormat, lang, ngZone);
  }

  public getSelectedLanguageFromSessionStorage() {
    const langID = sessionStorage.getItem(SESSION_STORAGE_LANGUAGE);
    if (langID) {
      return JSON.parse(langID) as string;
    }
    return "EN";
  }
  public setSelectedLanguageInSessionStorage(langID: string) {
    sessionStorage.setItem(
      SESSION_STORAGE_LANGUAGE,
      JSON.stringify(langID)
    );
  }
}
// https://runebook.dev/fr/docs/angular/guide/i18n
// https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
// https://github.com/angular/angular-cli/issues/22088
