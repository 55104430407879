import { Component } from '@angular/core';
import { delay, map, Observable } from 'rxjs';
import { HttpFeedbackService } from './shared/services/http-feedback.service';
import { I18nService } from './shared/services/i18n.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showSpinner: Observable<boolean>;
  selectedLanguage = 'EN';

  constructor(
    httpFeedback: HttpFeedbackService,
    private translateService: I18nService) {
      this.selectedLanguage = this.translateService.getSelectedLanguageFromSessionStorage();
      this.showSpinner = httpFeedback.activeRequestsCount.pipe(
        delay(0), // https://blog.angular-university.io/angular-debugging/
        map(count => count > 0)
      );
  }
}
