import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpFeedbackService {
  private readonly count: BehaviorSubject<number> = new BehaviorSubject(0);

  /** Return the current count of active HTTP requests. */
  public readonly activeRequestsCount: Observable<number> = this.count.asObservable();

  public incrementCount(): void {
    this.count.next(this.count.value + 1);
  }

  public decrementCount(): void {
    // in case the counter got created when requests were already active
    if (this.count.value > 0) {
      this.count.next(this.count.value - 1);
    }
  }
}
