// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: (window as any).SGSCustom.sgsEnvProperties.environment === 'production'
    || (window as any).SGSCustom.sgsEnvProperties.environment === 'uat',
  resource: "api.esg-platform-habilitation-greenscope",
  unauthorizedSgMarketsUrl: 'https://shared-uat.fr.world.socgen/unauthorized/?redirectUri=https://esgpere-hom.sgmarkets.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
