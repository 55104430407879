import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/i18n.service';

@Pipe({
  name: 'sgCurrency',
})
export class SgCurrencyPipe extends CurrencyPipe implements PipeTransform {
  constructor(private readonly i18n: I18nService) {
    super(i18n.currentLang);
  }

  transform(value: null | undefined, currencyCode?: string, display?: string | boolean, digitsInfo?: string, locale?: string): null;
  transform(value: string | number | null | undefined, currencyCode?: string, display?: string | boolean, // NOSONAR: upstream signature
    digitsInfo?: string, locale?: string): string | null; // split for eslint…
  transform(value: any, currencyCode?: any, display?: any, digitsInfo?: any, locale?: any): string | null {
    return super.transform(value, currencyCode, display, digitsInfo, locale || this.i18n.currentLang);
  }
}
