<nav class="navbar .border border-opacity-70 border-bottom border-top bg-lvl1">
  <div class="navbar-title">
    <a class="navbar-title-link">
      <div class="navbar-logo">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0" y="0" width="100%" height="50%" fill="#E60028"></rect>
          <rect x="0" y="50%" width="100%" height="50%" fill="#1D1D1B"></rect>
          <rect x="17%" y="46.5%" width="65%" height="7%" fill="white"></rect>
        </svg>
      </div>
      <div class="navbar-title-divider"></div>
      <div class="navbar-service-name" translate>ESG Platform Clarity</div>
    </a>
    <ng-container dropdown>
      <button
        dropdownToggle
        class="navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-offset="0,4"
        aria-expanded="true">
        <svg
          class="icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="2" y="6" width="20" height="2" fill="currentColor"></rect>
          <rect x="2" y="11" width="20" height="2" fill="currentColor"></rect>
          <rect x="2" y="16" width="20" height="2" fill="currentColor"></rect>
        </svg>
      </button>
      <ul class="navbar-menu-dropdown dropdown-menu w-100" *dropdownMenu>
        <ng-container *ngFor="let item of menu">
          <li class="navbar-item" *ngIf="item.visible">
            <a class="navbar-link" routerLink="{{ item.route }}" translate>
              <div class="track-and-confirm"></div>{{ item.title }}</a>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
  <div class="navbar-content">
    <ul class="navbar-navigation m-0">
      <ng-container *ngFor="let item of menu">
        <li class="navbar-item" *ngIf="item.visible">
          <a
            class="navbar-link"
            routerLink="{{ item.route }}"
            routerLinkActive="active"
            translate>
            <div class="track-and-confirm"></div>
            {{ item.title }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="navbar-toolbar">
    <div class="d-flex justify-content-end align-items-center">
      <sgwt-account-center
        #sgwtAccountCenter
        available-languages="EN,FR"
        language="selectedLanguage"
        authentication="sg-connect-v2"
        [environment]="env === 'production' ? '' : env"
        mode="sg-markets"
        show-sign-in-button="true"
        (sgwt-account-center--language-changed)="onLanguageChanged($event)">
      </sgwt-account-center>
    </div>
  </div>
</nav>
