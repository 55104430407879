import { setupSGWTConnect, SGWTConnectCore } from '@sgwt/connect-angular';

export const AUTHORIZATION_ENDPOINT_HOMOLOGATION = 'https://sgconnect-hom.fr.world.socgen/sgconnect';
export const AUTHORIZATION_ENDPOINT_PRODUCTION = 'https://sso.sgmarkets.com/sgconnect';

interface SgwtConnectHTMLElement extends HTMLElement {
  sgwtConnect: SGWTConnectCore;
  setSgwtConnectInstance: (sgwtConnectInstance: SGWTConnectCore) => void;
}

// This is a sample SG Connect Client ID for use in testing our whiteapp.
export const sgwtConnect = setupSGWTConnect({
  authorization_endpoint: (window as any).SGSCustom.sgsEnvProperties.sgConnect_authorization_endpoint, // eslint-disable-line
  client_id: (window as any).SGSCustom.sgsEnvProperties.sgConnect_client_id, // eslint-disable-line
  scope: (window as any).SGSCustom.sgsEnvProperties.sgConnect_scope, // eslint-disable-line
  acr_values: (window as any).SGSCustom.sgsEnvProperties.sgConnect_acr_values, // eslint-disable-line
  // Redirection is made to a dedicated page, cf. explanations in that file.
  redirect_uri: '/silent-renew.html' // eslint-disable-line
});

// Now, pass the sgwtConnect instance to the widget
const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
if (widget) {
  // When the code is executed, the widget may not be been fully initialized.
  // So, to verify that, we check the existence of the `widget.setSgwtConnectInstance()` function.
  // If it is not the case, we wait for the 'sgwt-connect--ready' event.
  if (typeof widget.setSgwtConnectInstance === 'function') {
    // Widget is already initialized...
    widget.setSgwtConnectInstance(sgwtConnect);
  } else {
    // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
    const handleSgwtConnectReady = () => {
      widget.setSgwtConnectInstance(sgwtConnect);
      // Remove the event listener
      widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    };

    widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
  }
}
