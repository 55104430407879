import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-http-feedback',
  templateUrl: './http-feedback.component.html',
  styleUrls: ['./http-feedback.component.scss']
})
export class HttpFeedbackComponent {
  @Input() showSpinner = false;
}
