import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SGWTConnectCore } from '@sgwt/connect-angular';
import { ISgwtConnectElement } from 'src/main';
import { BehaviorSubject, Subject, combineLatest, takeUntil, tap } from 'rxjs';
import { ProviderModel } from 'src/app/core/models/provider.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-vendor-service',
  templateUrl: './vendor-service.component.html',
  styleUrls: ['./vendor-service.component.scss'],
})
export class VendorServiceComponent implements AfterViewInit, OnInit, OnDestroy {
  private _providerInfo?: ProviderModel | null;


  @Input() get providerInfo(): ProviderModel | null | undefined {
    return this._providerInfo;
  }

  public tokenBearer: string | null = null;
  set providerInfo(value: ProviderModel | null | undefined) {
    this._providerInfo = value;
    this.providerInfo$.next(value);
  }

  public loaded = false;

  
  private sgwtConnect?: SGWTConnectCore;
  
  private afterViewInit$ = new Subject<void>();
  private providerInfo$ = new BehaviorSubject<ProviderModel | null | undefined>(null);
  private destroy$ = new Subject<void>();
  
  public iframeUri: ProviderModel | null | undefined;
  
  constructor() {}

  ngOnInit(): void {
    combineLatest([this.afterViewInit$, this.providerInfo$])
    .pipe(
      tap(() => this.initialize()),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private initialize() {
    this.iframeUri = this.providerInfo$.value;
    const wrapper = document.querySelector('sgwt-connect') as ISgwtConnectElement;
    this.sgwtConnect = wrapper.sgwtConnect;

    if (this.sgwtConnect && this.iframeUri) {
      this.sgwtConnect.fetchUserInfo((error, claims:any) => {
        const permissons  = claims.user_authorization.find((u:any) => u.resource === environment.resource)?.permissions;
        if(!permissons || permissons.length === 0) {
          window.location.href = environment.unauthorizedSgMarketsUrl;
        }
      });

      this.tokenBearer = this.sgwtConnect.getAuthorizationHeader()!.replace('Bearer ', '');
      this.iframeUri!.webAppUri += `?accessToken=${this.tokenBearer}`;
      if(this.iframeUri.callBackUri){
        this.iframeUri!.webAppUri += `&callbackUrl=${this.iframeUri.callBackUri}`;
      }
    }
  }
}
