import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BearerTokenService {
  private token: string | null = null;

  public setToken(token: string) {
    this.token = token;
  }

  public getToken() {
    return this.token;
  }
}
