import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/i18n.service';

@Pipe({
  name: 'sgDate',
})
export class SgDatePipe implements PipeTransform {
  constructor(private readonly i18n: I18nService) { }

  transform(rawDate: string | Date, zone?: string, format?: string, lang?: string): string {
    return this.i18n.formattedNgL10nDate(rawDate, zone, format, lang);
  }
}
