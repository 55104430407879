import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { sgwtConnect } from './sgwt-connect';

import { defineCustomElements } from '@sg-bootstrap/components/loader';
import { SGWTConnect } from '@sgwt/connect-angular';

export interface ISgwtConnectElement extends HTMLElement {
  sgwtConnect: SGWTConnect;
}

if (environment.production) {
  enableProdMode();
}

if (sgwtConnect.isAuthorized()) {
  // User is authorized, we bootstrap the Angular application.
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
} else if (sgwtConnect.getAuthorizationError()) {
  // An authorization error occured.
  document.body.innerHTML = `
    <div class="alert alert-danger" role="alert">
      Authorization error: ${sgwtConnect.getAuthorizationError()}.
      <br/>
      Please contact your support.
    </div>
  `;
} else {
  // User is not authorized, we request an authorization.
  sgwtConnect.requestAuthorization();
}

// Initialize SG Bootstrap web components
defineCustomElements(window);

