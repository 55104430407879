import { Injectable } from '@angular/core';

interface SgwtWebAnalyticsHTMLElement extends HTMLElement {
  trackEvent(category: string, action: string, name?: string, value?: string): void;
  trackSearch(keyword: string, category: string, count: number): void;
  trackLink(href: string, linkType: string): void;
  trackPageView(href?: string, title?: string): void;
}

export const pages = {
  home: 'Home',
};

export const categories = {
  pages,
  navbar: 'Navbar',
};

export const actions = {
  navigate: 'Navigate',
};

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class AnalyticsService {
  private getWidget(): SgwtWebAnalyticsHTMLElement {
    return document.querySelector(
      'sgwt-web-analytics'
    ) as SgwtWebAnalyticsHTMLElement;
  }

  trackEvent(category: string, action: string, name?: string, value?: string) {
    setTimeout(
      () => {
        const sgwtAnalyticsWidget = this.getWidget();
        if (!sgwtAnalyticsWidget?.trackEvent) {
          console.error('SGWT Analytics widget not found !');
          return;
        }
        sgwtAnalyticsWidget.trackEvent(category, action, name, value);
      },
      0
    );
  }

  trackEventNavigateHomePage() {
    this.trackEvent(
      categories.pages.home,
      actions.navigate
    );
  }
}
