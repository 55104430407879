import { catchError, map, Observable, of, shareReplay } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ProviderModel } from "../models/provider.model";

@Injectable({
    providedIn: 'root'
})
export class ProvidersService {
    providers$: Observable<ProviderModel[]>

    constructor(private httpClient: HttpClient) {
        this.providers$ = 
        this.httpClient.get('../../assets/static-data/providers.json').pipe(
            catchError(() => of({ values: [] })),
            map((response:any) => response.values ?? []),
            shareReplay(1));
    }

    getProviders(): Observable<ProviderModel[]> {
        return this.providers$;
    }

    getProvider(providerName: string | null): Observable<ProviderModel | null> {
        if(providerName !== null) {
            return this.providers$
            .pipe(
                map(providers => {
                    return providers.find(provider => provider.name === providerName) ?? null;
                })
            );
        }

        return of(null);
        
    }
}
