import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BearerTokenService } from '../services/bearer-token.service';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  constructor(private readonly bearerTokenService: BearerTokenService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.feedbackService.incrementCount();
    const authorizationHeader = request.headers.get('authorization');
    if (authorizationHeader) {
      this.bearerTokenService.setToken(authorizationHeader?.replace('Bearer ', ''));
    }
    return next.handle(request);
  }
}

export const bearerTokenInterceptorProvider = [
  { provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true }
];
