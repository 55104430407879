<main class="vh-100 d-flex flex-column">
  <app-navbar></app-navbar>
  <app-http-feedback *ngIf="(showSpinner | async) as show" [showSpinner]="show">
  </app-http-feedback>

  <router-outlet></router-outlet>

  <sgwt-help-center
    id="sgwt-help-center"
    application-id="esg-pere"
    [language]="selectedLanguage">
  </sgwt-help-center>

  <sgwt-mini-footer
    mode="sg-markets" 
    cookies-consent
    contact-us-by-help-center
    class="mt-auto bg-lvl1">
  </sgwt-mini-footer>

  <sgwt-splash-screen
    id="sgwt-splash-screen"
    application-id="esg-pere">
  </sgwt-splash-screen>
</main>

