import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  menu: any[] = [];
  @ViewChild('sgwtAccountCenter', { static: false })
  private sgwtAccountCenter?: ElementRef;
  private window: Window;
  private isListeningToReadyEvent = false;
  env: undefined;
  public BUS_USER_INFO = 'sg-connect.user-info';
  private GLOBAL_LANGUAGE = 'global.language';
  public selectedLanguage = 'EN';

  constructor(
    @Inject('Window') private readonly _window: Window,
    public translateService: I18nService) {
    this.window = _window;
    this.selectedLanguage =
      this.translateService.getSelectedLanguageFromSessionStorage();
  }

  ngOnInit(): void {
    this.initializeBusLanguageSubscription(); //Talk to Bangalore to remove ALL getSelectedLanguageFromSessionStorage() usage in webapp/widgets
    const sgsCustom = (this.window as any).SGSCustom;
    this.env = sgsCustom.sgsEnvProperties.environment;
  }

  ngAfterViewInit(): void {
    if (this.sgwtAccountCenter) {
      setTimeout(() => this.updateWidgetWhenReady(), 0);
    }
  }

  private initializeBusLanguageSubscription(): void {
    const bus = (window as any).SGWTWidgetConfiguration?.bus;
    bus?.subscribe(this.GLOBAL_LANGUAGE, (language: string | undefined) => {
      if (language) {
        this.translateService.useLanguage(language);
      }
    });
  }

  private unsubscribeBusLanguage(): void {
    const bus = (window as any).SGWTWidgetConfiguration?.bus;
    bus?.unsubscribe(this.GLOBAL_LANGUAGE);
  }

  private preferredLanguage() {
    const sgwtAccount = document.querySelector('sgwt-account-center') as any;
    this.translateService.use(this.selectedLanguage);
    sgwtAccount?.changeLanguage(this.selectedLanguage);
  }

  private updateWidgetWhenReady(): void {
    this.isListeningToReadyEvent =
      this.sgwtAccountCenter?.nativeElement.changeLanguage === undefined;
    if (this.isListeningToReadyEvent) {
      // not yet initialized: wait for the ready event…
      this.sgwtAccountCenter?.nativeElement.addEventListener(
        'sgwt-account-center--ready',
        this.onReady
      );
    } else {
      // already initialized: apply the change immediately
      this.onReady();
    }
  }

  ngOnDestroy(): void {
    if (this.sgwtAccountCenter && this.isListeningToReadyEvent) {
      this.sgwtAccountCenter.nativeElement.removeEventListener(
        'sgwt-account-center--ready',
        this.onReady
      );
    }
    this.unsubscribeBusLanguage();
  }

  onReady = () => {
    this.preferredLanguage();
  };


  onLanguageChanged(evt: Event) {
    const lang = (evt as CustomEvent).detail.language.toLowerCase();
    this.translateService.setSelectedLanguageInSessionStorage(lang);
    this.translateService.use(lang);
    if (lang && lang !== this.selectedLanguage) {
      window.location.reload();
    }
    if (document.querySelector('sgwt-help-center')) {
      (document.querySelector('sgwt-help-center') as any).setLanguage(lang);
    }
  }
}
