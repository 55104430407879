import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/i18n.service';

@Pipe({
  name: 'sgNumber',
})
export class SgNumberPipe extends DecimalPipe implements PipeTransform {
  constructor(private readonly i18n: I18nService) {
    super(i18n.currentLang);
  }

  transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
  transform(value: string | number | null | undefined, digitsInfo?: string, locale?: string): string | null; // NOSONAR: upstream signature
  transform(value: any, digitsInfo?: any, locale?: any): string | null {
    return super.transform(value, digitsInfo, locale || this.i18n.currentLang);
  }
}
