import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpFeedbackService } from '../services/http-feedback.service';

@Injectable()
export class HttpFeedbackInterceptor implements HttpInterceptor {
  constructor(private readonly feedbackService: HttpFeedbackService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.feedbackService.incrementCount();
    return next.handle(request).pipe(
      finalize(() => {
        this.feedbackService.decrementCount();
      })
    );
  }
}

export const httpFeedbackInterceptorProvider = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpFeedbackInterceptor, multi: true }
];

// https://grokonez.com/frontend/angular/angular-6/angular-6-http-interceptor-with-node-js-restapis
// https://stackoverflow.com/a/51085644
